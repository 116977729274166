const en_US = {
  platform: 'Al Voice Agent for Deep Respondents Insights',
  Log_In: 'Log in',
  Log_up: 'Sign up',
  Forget_Password: 'Forgot your password?',
  // ForgotPassword: 'Send Reset Link',
  ReturnLogin: 'Return to Login',
  Sign_up: 'Sign up',
  Home: 'Home',
  CreateInterview: 'Create',
  ExecuteInterview: 'Launch',
  Manage: 'Manage',
  TestInterview: 'Test Run',
  GeneralKnowledge: 'Sythesis',
  Report: 'Report',
  UserCenter: 'User Center',
  Payment: 'Payment',
  Search: ' Search',
  Welcome: 'Welcome,',
  Confirm: 'Confirm',
  adminManage: 'adminManage',
  UserPoints: 'UserPoints',
}
export default en_US
