import React, { useState, useEffect, createContext, lazy } from 'react'
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  Navigate,
} from 'react-router-dom'
import { ConfigProvider, message, Affix } from 'antd'
import GoogleAnalytics from './utils/GoogleAnalytics'
import ReactGA from 'react-ga4'
import zh_cn from 'antd/locale/zh_CN'
import eng from 'antd/locale/en_US'
import 'dayjs/locale/zh-cn'
import { IntlProvider } from 'react-intl'
import zh_CN from './static/locale/zh-CN'
import en_US from './static/locale/en-US'
import PrivateRoute from './pages/PrivateRoute/privateRoute'
import Chat from './static/images/chat.png'
import ChatDelete from './static/images/chat-delete.png'
import { LINK_URL } from './utils/url'
// import { HTML5Backend } from 'react-dnd-html5-backend'
// import { DndProvider } from 'react-dnd'
import './App.css'
import http from './utils/http'
import 'tailwindcss/tailwind.css'

const Login = lazy(() => import('./pages/login/login'))
const Header = lazy(() => import('./pages/header/index'))

const ForgotPassword = lazy(() => import('./pages/forgotPassword/index'))
const Signup = lazy(() => import('./pages/signup/index'))
const AiTestRuns = React.lazy(() => import('./pages/aiTestRuns/index'))
const Preview = React.lazy(() => import('./pages/preview/index'))
const Graphrag = React.lazy(() => import('./pages/graphrag/index'))
const AiBubble = React.lazy(() => import('./components/aiBubble'))

export const myContext = createContext({
  locale: '',
  userName: '',
  languageChange: () => {},
  onLogin: () => {},
  onGoogleLogin: () => {},
})

function App() {
  const [ifShow, setIfShow] = useState('none')
  const [locale, setLocale] = useState('en')
  const [userName, setUserName] = useState('')

  const [auth, setAuth] = useState(false)
  const navigate = useNavigate()
  // const [userinfo, setUserinfo] = useState({})

  useEffect(() => {
    let lang = ''
    if (localStorage.getItem('locale')) {
      lang = localStorage.getItem('locale')
    } else {
      lang = 'zh'
      localStorage.setItem('locale', lang)
    }
    if (lang === 'zh') {
      setLocale('en')
      // moment.locale('zh-cn')
    } else {
      setLocale('en')
      // moment.locale('en')
    }
  }, [])

  const languageChange = (lang) => {
    setLocale(lang)
    localStorage.setItem('locale', lang)
  }

  const onGoogleLogin = (access_token, param) => {
    if (access_token) {
      http
        .get('/api/auth/googleLogin?accessToken=' + access_token)
        .then((res) => {
          setAuth(access_token ? true : false)
          if (param) {
            navigate(param)
          } else {
            navigate('/dashboard/createInterview')
          }
          localStorage.setItem('auth', access_token ? true : false)
          // 用户信息
          http.get('api/auth/userinfo').then((res) => {
            // setUserinfo(res)
            setUserName(res.username)
            let data = JSON.stringify(res)
            ReactGA.gtag('set', 'user_properties', {
              user_email: res.username,
              username: res.username.split('@')[0],
            })
            localStorage.setItem('userinfo', data ? data : '')
          })
        })
        .catch((res) => {
          message.error(res.response.data.msg)
        })
    }
  }
  const onLogin = (obj, param) => {
    if (obj) {
      // const params = props.params
      http
        .post('/api/auth/login', obj)
        .then((res) => {
          // 用户信息
          http.get('api/auth/userinfo').then((res) => {
            console.log('用户信息11111')
            // setUserinfo(res)

            setUserName(res.username)
            let data = JSON.stringify(res)
            localStorage.setItem('userinfo', data ? data : '')
            setAuth(obj ? true : false)
            localStorage.setItem('auth', obj ? true : false)
            if (param) {
              navigate(param)
            } else {
              navigate('/dashboard/createInterview')
            }
            console.log(res.username.split('@')[0])
            ReactGA.gtag('set', 'user_properties', {
              user_email: res.username,
              username: res.username.split('@')[0],
            })
          })
        })
        .catch((res) => {
          message.error(res.response.data.msg)
        })
    }
  }
  const contextVal = {
    languageChange,
    locale,
    userName,
    onLogin,
    onGoogleLogin,
  }

  const messages = {
    en: en_US,
    zh: zh_CN,
  }
  return (
    //  <DndProvider backend={HTML5Backend}>
    //     <Card />
    //   </DndProvider>

    <IntlProvider
      locale={locale === 'zh' ? 'zh' : 'en'}
      messages={messages[locale]}
    >
      <ConfigProvider locale={locale === 'zh' ? zh_cn : eng}>
        <div
          style={{
            width: '350px',
            height: '400px',
            backgroundColor: '#e2ebe7',
            borderRadius: '10px',
            position: 'fixed',
            bottom: 90,
            right: 10,
            zIndex: 999,
            display: ifShow,
          }}
        >
          {ifShow === 'block' ? <AiBubble /> : <div></div>}
        </div>
        <div
          onClick={() => {
            if (ifShow === 'block') {
            }
            setIfShow(ifShow === 'none' ? 'block' : 'none')
          }}
          style={{
            position: 'fixed',
            bottom: 30,
            right: 10,
            zIndex: 9999,
            width: '50px',
            height: '50px',
            backgroundColor: '#529887',
            borderRadius: '50%',
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow:
              '0 0 10px rgb(82, 152, 135,0.8), 0 0 20px rgb(82, 152, 135,0.6),0 0 30px rgb(82, 152, 135,0.4)',
          }}
        >
          <img
            width={40}
            height={40}
            src={ifShow === 'none' ? Chat : ChatDelete}
            alt=""
          />
        </div>
        <myContext.Provider value={contextVal}>
          <GoogleAnalytics>
            <Routes>
              <Route>
                <Route
                  path="/dashboard"
                  element={<Navigate to="/dashboard/createInterview" />}
                />
                <Route path="/dashboard/*" element={<Header />} />
              </Route>
              <Route path="/dashboard/login" element={<Login />} />
              <Route
                path="/dashboard/forgot-password"
                element={<ForgotPassword />}
              />
              <Route path="/dashboard/signup" element={<Signup />} />
              <Route path="/dashboard/aiTestRuns" element={<AiTestRuns />} />
              <Route path="/dashboard/preview/:id" element={<Preview />} />
              <Route path="/dashboard/graphrag" element={<Graphrag />} />
            </Routes>
          </GoogleAnalytics>
        </myContext.Provider>
      </ConfigProvider>
    </IntlProvider>
  )
}

export default App
